@mixin background-size($size){
	-webkit-background-size: $size;
	-moz-background-size: $size;
	-o-background-size: $size;
	-ms-background-size: $size;
	background-size: $size;
}

@mixin border-radius($radius) {
	-webkit-border-radius:$radius;
	-moz-border-radius:$radius;
	-o-border-radius:$radius;
	-ms-border-radius:$radius;
	border-radius:$radius;
}

@mixin border-topleft-radius($topLeftRadius) {
	-webkit-border-top-left-radius:$topLeftRadius;
	-moz-border-top-left-radius:$topLeftRadius;
	-o-border-top-left-radius:$topLeftRadius;
	-ms-border-top-left-radius:$topLeftRadius;
	border-top-left-radius:$topLeftRadius;
}

@mixin border-bottomleft-radius($bottomLeftRadius) {
	-webkit-border-bottom-left-radius:$bottomLeftRadius;
	-moz-border-bottom-left-radius:$bottomLeftRadius;
	-o-border-bottom-left-radius:$bottomLeftRadius;
	-ms-border-bottom-left-radius:$bottomLeftRadius;
	border-bottom-left-radius:$bottomLeftRadius;
}

@mixin box-shadow($shadow){
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
	-o-box-shadow: $shadow;
	-ms-box-shadow: $shadow;
	box-shadow: $shadow;
}

@mixin transition($type) {
	-webkit-transition: $type;
    -moz-transition: $type;
	-o-transition: $type;
	-ms-transition: $type;
    transition: $type;
}

@mixin transition-duration($time) {
	-webkit-transition-duration: $time;
	-moz-transition-duration: $time;
	-o-transition-duration: $time;
	-ms-transition-duration: $time;
    transition-duration: $time;
}

@mixin transform($trans){
	-webkit-transform: $trans;
	-moz-transform: $trans;
	-o-transform: $trans;
	-ms-transform: $trans;
	transform: $trans;
}

@mixin backface-visibility($visibility){
	-webkit-backface-visibility: $visibility;
	-moz-backface-visibility: $visibility;
	-o-backface-visibility: $visibility;
	-ms-backface-visibility: $visibility;
	backface-visibility: $visibility;
}