header {
	height:72px;
	width: $full;
	position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1210;

	.container-fluid {
		height:inherit;
	}

	.menu-icon {
		display: inline-block;
		height: inherit;
		vertical-align: top;
		line-height: 72px;
		margin-left: -15px;
		padding: 30px 15px;
		font-size: 20px;
		cursor: pointer;
		margin-right: 15px;

		&.active {

			.line {
				&.top {
					width: 12px;
					@include transform(translateX(0) translateY(1px) rotate(145deg));
				}
				&.bottom {
					width: 12px;
					@include transform(translateX(0) translateY(-1px) rotate(-145deg));
				}
			}

		}

		.line {
			width: 18px;
			height: 2px;
			@include transition(all);
			@include transition-duration(.3s);
			z-index: 10;

			&.center {
				margin: 3px 0;
			}

		}

	}

	.logo {
		height:inherit;
		display:inline-block;

		a {
			display:block;
			height:55px;
			margin-top:9px;
		}

	}

	.header-right-section {
		float:right;
		min-width:460px;
		height: 50px;
		margin-top: 9px;
		text-align:right;

		ul.top-nav {
			display:inline-block;
			vertical-align:middle;
			margin-top:5px;

			li {
				display:inline-block;
				position:relative;

				> a {
					font-size:20px;
					display: block;
					padding: 5px 10px;
					margin-left: 10px;
					margin-top: 5px;
					@include border-radius(3px);

					.count {
						font-size:10px;
						line-height:9px;
						padding:5px 3px;
						position:absolute;
						top:0px;
						right:0px;
						@include border-radius(3px);
					}

				}

				a.search-destination {
					&.active {
						position:relative;
						z-index:5;
					}
				}

				// search
				.search-bar {
					position: absolute;
					top: 5px;
					left: -456px;
					width: 500px;
					z-index: 4;
					height: 38px;

					input {
						height:inherit;
					}

				}

				.toggle-element {
					position:absolute;
					top:42px;
					right:0px;
					width:250px;
					height:194px;
					overflow:auto;
					z-index:10;

					@include border-radius(6px);
					@include box-shadow(0px 0px 7px rgba(0,0,0,0.3));

					li {
						display: block;
						text-align: left;

						&:last-child {
							border-bottom:none;
						}

						a {
							margin:0px;
							padding:5px 10px;
							@include border-radius(0px);
							cursor: pointer;
							font-size: 14px;

							&:hover, &:focus {
								text-decoration:none;
							}

							span {
								float:left;
								font-size: 19px;
								width: 30px;
								height: 30px;
								text-align: center;
								line-height: 30px;
								border-radius: 30px;
								margin-top:4px;
							}

							p {
								font-size: 15px;
								margin-bottom: 0;

								strong, small {
									display:block;
								}

							}

						}

						&.building-group {
							.building-group-details {
								background-color: #efefef;
								a {
									display: block;
									font-weight: bold;
								}
								.group-control {
									padding: 1px 2px;
									clear: right;
								}
								.group-building-list {
									padding: 5px 10px;
									li {
										text-align: right;
    									color: #000;
										border-bottom: solid 1px #aaa;
										&:last-child {
											border-bottom: none;
										}
									}
								}
							}
						}

						.view-all-btn {
							text-align: center;
							font-size: 15px;
							padding: 10px;

						}

					}

				}

				.toggle-building-elm {
					overflow:hidden;
					height:340px;
				}
				.toggle-notification-elm {
					overflow:hidden;
					height:210px;
				}

				.building-lists {
					height:301px;
					overflow:auto;
					width:100%;
				}
				.notification-lists{

					height:171px;
					overflow:auto;
					width:100%;
				}

			}

		}

		ul.user-info-nav {
			display:inline-block;
			margin-left: 20px;
			vertical-align:middle;

			> li {
				> a {
					display:block;

					> img {
						margin-right: 4px;
						float:left;
						height: 34px;
						width: 34px;
						@include border-radius(40px);
					}

					> div {
						padding-left:48px;
					}

					small {
						font-size:$full;

						em {
							font-style:normal;
						}
					}

					hr {
						margin-top:5px;
					}

					span {
						margin-left: 9px;
					}

					&:hover, &:focus {
						text-decoration:none;
					}

					small.lang-info {
						display:block;
						text-align: left;
						font-size:11px;
					}

				}

				/*ul.dropdown-menu {
					width:$full;
					min-width: 171px;
					padding:0px;
					left: auto;
					right: 0px;

					li {
						a {
							padding:6px 10px;
							border:none;

							i {
								margin-right:5px;
							}

						}

						ul.lang-section {

							li {
								strong {
									display:block;
									padding:10px;
								}

								a {
									display:block;

									img {
										margin-top:-3px;
									}

									i {
										display:none;
									}

									&:hover, &:focus {
										text-decoration:none;
									}

									&.language-active {
										i {
											display:block;
										}
									}

								}

							}

						}
					}

				}*/


			}

		}

	}



}

/*  Added for new UI - starting 09/19/17 */

$bg-badge: $cbre-lime;

header.header {
	background-color: $cbre-primary;
	.container, .container-fluid {
		.menu-icon {
			.line {
				background-color: $white;
			}
		}
		.header-content {
			display: inline-block;
			float: right;
			margin-top: 15px;
			text-align: right;
			@media screen and (min-width: 320px) and (max-width: 768px) {
				display: none;
			}
			&__nav {
				position: relative;
					top: 5px;
				display: inline-block;
				margin: 0;
				padding: 0;
				list-style-type: none;
				li {
					float: left;
					margin-right: 30px;
					a {
						position: relative;
						color: $white;
						font-size: 2.4rem;
						> i + span.count {
							position: absolute;
								top: -5px;
								left: 10px;
							padding-top: 5px;
							min-width: 20px;
							height: 20px;
							border-radius: 50%;
							background-color: $bg-badge;
							text-align: center;
							font-size: 1.2rem;
							line-height: 1.2rem;
							&:empty {
								display: none;
							}
						}
					}
				}
			}
			&__userinfo {
				position: relative;
				display: inline-block;
				color: $white;
				.userinfo {
					&:hover {
						cursor: pointer;
					}
					&__profilepic {
						float: left;
						margin-right: 15px;
						width: 40px;
						height: 40px;
						border-radius: 50%;
						background-color: $white;
					}
					&__details {
						display: inline-block;
						i.fa {
							margin-right: 5px;
						}
						i + div.username {
							display: inline-block;
							white-space: nowrap;
						}
					}
				}
				.userinfo-expanded {
					position: relative;
						top: 10px;
					/*display: inline-block;*/
					/*float: right;*/
					margin: 0;
					padding: 10px 15px;
					border: none;
					border-radius: 0;
					z-index: initial;
					&:before {
						content: '';
						position: absolute;
							top: -3px;
							left: 8px;
						width: 25px;
						height: 25px;
						background-color: $white;
						transform: rotate(45deg);
						z-index: -1;
					}
					.action-item {
						margin-bottom: 5px;
						font-size: 1.6rem;
						a {
							color: $link-default;
							text-decoration: none;
							&:hover {
								color: $link-default-hover;
							}
							i {
								position: relative;
									top: 1px;
								margin-right: 10px;
								font-size: 1.8rem;
							}
						}
					}
					hr {
						margin: 7px 0;
						border-top: 1px solid lighten($black, 90%);
					}
					h5 {
						margin-bottom: 7px;
						color: $text-default;
					}
					.language {
						margin-bottom: 5px;
						img {
							margin-right: 7px;
							border: 1px solid lighten($black, 80%);
						}
						i {
							position: relative;
								top: 2px;
							float: right;
						}
					}
				}
			}
		}
	}
}
