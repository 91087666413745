@font-face {
  font-family: 'FuturaBT-Light';
  src: url("/assets/fonts/FuturaBT-Light.eot?#iefix") format("embedded-opentype"),
   url("/assets/fonts/FuturaBT-Light.woff") format("woff"), 
   url("/assets/fonts/FuturaBT-Light.ttf") format("truetype"), 
   url("/assets/fonts/FuturaBT-Light.svg#FuturaBT-Light") format("svg");
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'Futura Light';
  src: url('/assets/fonts/tt0138m_.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Book';
  src: url('/assets/fonts/tt0140m_.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Medium';
  src: url('/assets/fonts/tt0142m_.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Bold';
  src: url('/assets/fonts/tt0144m_.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Heavy';
  src: url('/assets/fonts/tt0146m_.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Light Condensed';
  src: url('/assets/fonts/tt0200m_.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Medium Condensed';
  src: url('/assets/fonts/tt0201m_.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Bold Condensed';
  src: url('/assets/fonts/tt0202m_.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Extra Bold Condensed';
  src: url('/assets/fonts/tt0204m_.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre-R';
  src: url("/assets/fonts/calibre/CalibreRegular.eot?#iefix") format("embedded-opentype"),
   url("/assets/fonts/calibre/CalibreRegular.woff") format("woff"), 
   url("/assets/fonts/calibre/CalibreRegular.ttf") format("truetype"), 
   url("/assets/fonts/calibre/CalibreRegular.svg#Calibre-R") format("svg");
  font-weight: normal;
  font-style: normal; 
}

@font-face {
  font-family: 'Calibre-B';
  src: url('/assets/fonts/calibre/CalibreBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre-L';
  src: url('/assets/fonts/calibre/CalibreLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre-LI';
  src: url('/assets/fonts/calibre/CalibreLightItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre-M';
  src: url('/assets/fonts/calibre/CalibreMedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre-RI';
  src: url('/assets/fonts/calibre/CalibreRegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre-SB';
  src: url('/assets/fonts/calibre/CalibreSemibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
