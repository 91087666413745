div[ui-view] {
  padding-top: 72px;
  padding-bottom: 80px;
  min-height: $full;
}


.body-content {
  padding-top: 0;
  padding-bottom: 73px;
  .container-fluid {
    padding: 30px 30px 0px;
    position: relative;

    .porfolio-header {
      margin-bottom: 30px;
      padding: 0px 5px;

      h3 {
        margin: 0px;
        display: inline-block;
        font-weight: bold;
        vertical-align: middle;
        font-size: 20px;

        i {
          font-size: 24px;
          margin-right: 5px;
        }

        span {
          font-size: 15px;
        }

      }

      .btn-group {
        min-width: 500px;
        margin-left: 10px;

        select {
          width: $full;
          padding: 6px;
        }

        .btn-group {
          border: solid 1px #ddd;
          @include border-radius(3px);

          button {
            width: $full;
            text-align: left;

            .caret {
              float: right;
              margin-top: 11px;
              margin-left: 10px;
            }

          }

        }

      }

      .period-info {
        position: absolute;
        width: 100%;
        left: 0px;
        top: 37px;
        text-align: center;
        z-index: -1;
      }

      .data-settings {
        float: right;
        margin-top: -2px;

        a {
          font-size: 23px;
        }

      }

    }

    .filterable-section {
      margin-top: 5px;

      .three-section-height {
        height: 527px;
        position: relative;
        overflow: hidden;
      }

      .row > * {
        padding: 0px 20px;
      }

    }

  }
}

.search-result {
  //position:absolute;
  top: 72px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  padding: 0px 15px;
  display: block;
  overflow: auto;

  .search-result-heading {
    h4 {
      margin: 20px 0px;
      font-size: 24px;
      padding: 0 10px;
      line-height: 37px;
    }
  }

  .search-result-body {
    h4.modal-title {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

}

.modal-open .modal {
  z-index: 3100 !important;

  &.confirm-window,
  &.modal-mail-sending {
    z-index: 3150 !important;
  }

}

.modal-backdrop {
  z-index: 3000 !important;
}

.modal-dialog {
  width: 840px;

  // &.modal-sm {
  // 	width: 300px;
  // }

}

.modal-lg {
  width: 820px;

  table {
    td {
      input {
        display: none;
      }
      input[type="checkbox"] {
        display: block;
      }
    }
  }

  .profile-details {
    padding: 10px 10px 0px;
    margin-bottom: 10px;

    > h4 {
      margin-top: 0px;
      font-weight: bold;
    }

    > img {
      float: left;
      width: 90px;
      padding: 5px;
      border: solid 1px #ddd;
      background: #fff;
    }

    > div {
      padding-left: 120px;
      min-height: 90px;
    }

  }

}

.modal-footer .info {
  float: left;
  line-height: 37px;
}

.manage-profile {

  .profile-details {
    background: #f3f0ff;
    border: solid 1px #dcd4f9;
    padding: 5px 10px;
    margin-bottom: 10px;
    img {
      max-width: 100px;
      padding: 5px;
      border: 1px solid #ddd;
      background: #fff;
    }
    .profile-details-list {

      float: left;
      margin-left: 10px;

    }
  }

}

//financial reports

#financialGrid tbody .form-control {
  display: none;
}

// checklists
td.details-control {
  background: url('/images/details_open.png') no-repeat center center;
  cursor: pointer;
}

tr.shown td.details-control {
  background: url('/images/details_close.png') no-repeat center center;
}

.stepwizard-step p {
  margin-top: 10px;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
 // z-order: 0;
}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  opacity: 1;
}

#newChecklist h3 {
  margin-top: 0px;
}

.setup-content table > tbody > tr > td > span {
  display: none;
}

.table-bordered > tbody > tr > td .btn {
  font-size: 14px;
  line-height: 15px;
  padding: 5px 6px;
}

//reports
.panel.dashboard-panel {
  border: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  min-height: 410px;
  @include box-shadow(0 0 7px rgba(0, 0, 0, 0.3));

  .panel-heading {
    border-bottom: solid 1px #ddd;
    padding: 5px 15px;

    > h4.subpanel-title {
      margin-bottom: 0px;
      margin-top: 3px;
    }

    .chart-type-links a.chart-btn {
      display: inline-block;
      padding: 2px 4px;
      margin-left: 7px;
      border-radius: 4px;
    }

    .chart-type-links a.chart-btn:last-child i {
      @include transform(rotate(90deg));
    }

  }
  .c3 {
    svg {
      margin-top: 10px;
    }
  }

  .panel-body {
    opacity: 0.8;
  }

}

.padding-right-close {
  padding-right: 5px;
  margin-bottom: 5px;
}

.padding-left-close {
  padding-left: 5px;
  margin-bottom: 5px;
}

div#canvasid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3000;
  text-align: center;

  canvas {
    margin-top: 20px;
  }

  .button-section {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 5px;
  }

}

.pull-left.dropdown-panel > .date-section {
  display: inline-block;

  .datepickerfield {
    font-size: 12px;
    max-width: 72px;
  }

}

.pull-left.dropdown-panel > * {
  margin-left: 10px;
}

.pull-left.dropdown-panel > *:first-child {
  margin-left: 0px;
}

.pull-left.dropdown-panel .dropdown,
.pull-left.chart-type-links .dropdown {
  display: inline-block;
  margin-left: 10px;

  .dropdown-menu {
    left: auto;
    right: 0px;
    top: 22px;
  }

}

// admin forms
.add-grid-section {
  h3 {
    margin-top: 0px;
  }

  .form-section {
    margin-top: 25px;

    h4 {
      font-weight: bold;
      padding-bottom: 10px;
      margin-bottom: 25px;
    }

    .form-control {
      height: 30px;
    }

    textarea.form-control {
      height: auto;
    }

    .control-label {
      padding-top: 0px;
    }

    .button-section {
      border-top: solid 1px $light;
      padding: 15px 0px;
      margin-bottom: -15px;
      @include border-radius(0px 0px 6px 6px);
    }

    .row {

      .main-select {
        padding: 4px 15px;
        display: inline-block;
        float: none;

        a.expand-collapse {
          float: left;
        }

        label {
          display: block;
          padding-left: 20px;
        }

      }

    }

    .photo {
      img {
        display: inline-block;
        height: 80px;
        border: solid 1px #ddd;
        background: #fff;
        padding: 2px;
        vertical-align: bottom;
        margin-right: 10px;
      }
    }

    .theme-preview {
      display: inline-block;
      margin-right: 30px;
      position: relative;

      img {
        height: 300px;
        @include box-shadow(0px 0px 10px rgba(0, 0, 0, 0.6));
        @include transition(all);
        @include transition-duration(0.3s);
        cursor: pointer;

        &:hover {
          transform: scale(1.05);
        }

      }

      .mask-theme {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: none;

        i {
          font-size: 50px;
          position: absolute;
          top: 50%;
          margin-top: -25px;
          left: 50%;
          margin-left: -25px;
        }

      }

    }

  }
}

.dropdown-multiselect {
  .dropdown-toggle {
    height: 28px;
    line-height: 28px;
    padding: 0px 10px;

    span.caret {
      float: right;
      margin-top: 12px;
    }

    + .dropdown-menu {
      padding: 0px 0px 5px;
      margin: 0px;
      max-height: 200px;
      width: 100%;

      li {
        position: relative;

        a {
          padding: 5px 10px;
        }

      }

      li[ng-class] {
        a {
          padding: 5px 10px;

          &:before {
            content: "";
            position: absolute;
            top: 4px;
            left: 7px;
            width: 21px;
            height: 20px;
            border: solid 1px #aaa;
          }

          > span:last-child {
            margin-left: 24px;
          }

          span.glyphicon + span {
            margin-left: 5px;
          }

        }
      }

    }

  }

  &:hover {
    background-color: $light;
  }

  &.open {
    .dropdown-toggle {
      background-color: $light;
      border-color: #66afe9;
    }
  }

}

.popover[class*="tour-"] {
  z-index: 3100;
}

.tour-backdrop {
  z-index: 10;
  opacity: 0.6;
}

.tour-step-background {
  z-index: 10;
}

.contact-setting {
  h4 {
    margin-top: 30px;
  }
  img {
    width: 90px;
    padding: 3px;
    background: #fff;
    border: solid 1px #ddd;
    vertical-align: bottom;
    margin-right: 10px;
  }
}

.ui-widget.ui-widget-content {
  z-index: 1300;
}

.modal {
  z-index: 4000;

  .modal-content {

    .modal-content {
      background-color: transparent !important;
    }

    .modal-header {
      .close {
        opacity: 0.6;
      }
      h4 {
        font-weight: bold;
      }
    }

    .modal-body {
      > .form-horizontal {
        padding: 0px;
      }

      .modal-footer {
        margin: 20px -15px -15px;
      }
    }

  }

}

// form screen
.form-content {
  // @include box-shadow(0px 0px 7px rgba(0,0,0,0.3));
  // @include border-radius(6px);

  .form-horizontal {
    padding: 0;

    .btn-panel {
      padding: 10px;
      hr {
        margin-bottom: 15px;
      }
    }

    .form-section {
      padding: 15px;

      h4 {
        font-weight: bold;
      }
      hr {
        margin-bottom: 20px;
      }

    }
  }

}

.accordion {
  @include box-shadow(0px 0px 7px rgba(0, 0, 0, 0.3));
  @include border-radius(3px);

  > ul {
    > li {
      > a {
        display: block;
        padding: 10px;

        &:hover, &:focus {
          text-decoration: none;
        }

        i {
          float: right;
          font-size: 12px;
          border-radius: 20px;
          padding: 3px;
        }

      }

      .accordion-content {
        display: none;
        max-height: 300px;
        overflow: auto;

        .list-group-item {
          border: none;
          padding: 0px;

          a {
            padding: 6px 10px;
            display: block;

            &:hover, &:focus {
              text-decoration: none;
            }

          }

          p {
            margin: 3px 0px;
            position: relative;
            z-index: 10;
            cursor: pointer;
            width: 100%;

            strong {
              font-size: 14px;
              display: block;
              margin-bottom: 0px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

          }

          .group-name {
            display: inline-block;
            height: 18px;
            width: 18px;
            @include border-radius(60px);
            text-align: center;
            line-height: 18px;
            font-size: 11px;
            vertical-align: top;
            margin-right: 5px;
          }

          section {
            font-size: 12px;
            margin-top: 20px;

            span {
              display: inline-block;
              margin-left: 5px;
            }

            time {
              float: right;
              font-size: 10px;
              padding: 0px 5px;
              line-height: 17px;
            }

          }

        }
      }

    }
  }

}

.md-15 {
  margin-bottom: 15px !important;
}

.inline-block {
  display: inline-block !important;
}

.toast-success {
  width: 34%;
  float: right;
}

.toast-top-right {
  top: 90px;
  right: 50%;
  margin-right: -150px;

  .toast-info {
    opacity: 1 !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.8) !important;
    background-color: #006f53;
  }

}

.customized-time-picker {
  margin-top: 10px;
  .uib-timepicker {
    position: relative;
    > tbody {
      tr.text-center {
        position: absolute;
        left: 0px;
        width: 0px;

        &:first-child {
          top: 0px;
        }

        &:last-child {
          bottom: 0px;
        }

        .uib-increment.hours {
          a {
            padding: 0px;
            line-height: normal;
            position: absolute;
            top: -5px;
            left: 19px;
            color: #999 !important;
          }
        }

        .uib-increment.minutes {
          a {
            padding: 0px;
            line-height: normal;
            position: absolute;
            top: -5px;
            left: 72px;
            color: #999 !important;
          }
        }

        .uib-decrement.hours {
          a {
            padding: 0px;
            line-height: normal;
            position: absolute;
            bottom: -7px;
            left: 19px;
            color: #999 !important;
          }
        }

        .uib-decrement.minutes {
          a {
            padding: 0px;
            line-height: normal;
            position: absolute;
            bottom: -7px;
            left: 72px;
            color: #999 !important;
          }
        }

      }
    }
  }
}

.single-table-responsive {
  min-height: 380px;
  overflow-y: hidden;
}

.dropdown-fix-firefox {
  position: relative;
  .fa-caret-down {
    position: absolute;
    top: 8px;
    right: 26px;
    z-index: 100;
    display: none;
    &.firefox-dropdown-icon {
      display: block;
    }
  }
}

// Multiselect dropdown list box arrow - for .dropdown-fix-firefox
.dropdown-multiselect .dropdown-toggle span.caret {
  position: absolute;
  right: 10px;
}

.posion-relative {
  position: relative;
}

.widget-check-mark {
  position: absolute;
  left: 0px;
  color: #ffffff;
  top: -2px;
  z-index: 1000;
}

@-ms-viewport {
  width: auto !important;
}

//chart bg
.chart-panel-bg {
  background: rgba(255, 255, 255, .5) !important;
}

.btn-screen-shot {
  right: 0;
  top: 60%;
  z-index: 2147483647;
  position: fixed;
}

.help-box {
  position: absolute;
  z-index: 1000;
  width: 333px;
  right: 0px;
  padding: 0px;
  top: 43px;
  line-height: 24px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  -ms-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.text-bold {
  font-weight: 700;
}

.title-text {
  border-radius: 3px 3px 0px 0px;
  background-color: #006f53;
  padding: 1px;
  text-align: left;
  color: #fff;
  line-height: 12px;
}

.help-content {
  padding: 5px;
  text-align: left;
  font-size: 13px;
  line-height: 15px;
}

.title-text > h5 {
  margin: 6px auto;
  padding-left: 3px;
}

#annotator {
  margin-top: 30px;
  padding: 3px 6px 2px 7px;
  border-radius: 3px 0px 0px 3px;
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background: transparent;
  z-index: 1101;
  top: 0;
  left: 0;
  display: none;
}

/*  Added for new UI - starting 09/19/17 */
html {
  font-size: 62.5%;
}

body {
  background-color: $white;
  font-size: 14px;
  font-size: 1.4rem;
}

div, ul, li, a, p, span, em, small, label, input, textarea {
  font-family: "Calibre-R", Arial, Helvetica, sans-serif;
  --mat-radio-checked-ripple-color: #00372d;
  --mdc-radio-selected-focus-icon-color: #003f2d ;
  --mdc-radio-selected-hover-icon-color: #003f2d ;
  --mdc-radio-selected-icon-color: #003f2d ;
  --mdc-radio-selected-pressed-icon-color: #003f2d ;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #003f2d 
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

a {
  color: $link-default;
  //webkit-transition: color 0.5s;
  transition: color 0.5s;
  text-decoration: none;
  &:hover {
    color: $link-default-hover;
   // webkit-transition: color 0.5s;
    transition: color 0.5s;
    text-decoration: none;
  }
}

nav.navbar {
  background-color: $white;
}

.body-content {
  border: none;
  /*background-color: $white;*/
  padding-top: 0px;
  .container, .container-fluid {
    margin: 0;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .page-header {
    margin: 0;
    padding: 0;
    padding-bottom: 20px;
    border: none;
    h3 {
      color: $text-default;
      font-family: "Calibre-R", Arial, Helvetica, sans-serif;
      font-size: 2.4rem;
      > i {
        margin-right: 10px;
      }
    }
  }

  .dataTables_wrapper {
    .dt-buttons {
      // float: left;
      a {
        color: $link-default;
        span {
          // display: none;
        }
        &:before {
          content: '\e9c7';
          position: relative;
          top: 0px;
          right: 0px;
          margin-right: 7px;
          font-family: "Calibre-R", Arial, Helvetica, sans-serif !important;
        }
        // &:after {
        // 	content: 'Download Excel';
        // 	position: relative;
        // 		top: -3px;
        // }
        &.btn-default:hover {
          color: $link-default-hover;
          background-color: transparent;
        }
      }
    }
    .dataTables_filter {
      label {
        padding-left: 10px;
        font-size: 1.6rem;
        &:after {
          // content: '\e986';
          position: relative;
          font-family: "Calibre-R", Arial, Helvetica, sans-serif !important;
          // font-size: 1.8rem;
        }
        input {
          position: relative;
          top: -3px;
          width: 200px;
          font-size: 1.6rem;
        }
      }
    }
  }

}









