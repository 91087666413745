footer.footer {
	/*height:50px;
	padding:15px;
	margin: -50px 0px 0px;
	background-color: $cbre-primary;
	.footer-logo {
		float:left;

		img {
			height:18px;
		}

	}

	.footer-nav {
		float:right;

		li {
			display:inline-block;
			margin-left:15px;
		}

	}

	.version {
		text-align:center;
	}*/
	position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color:$cbre-primary;
  color: $white;
	a.logo {
		display: inline-block;
		padding: 10px 0;
    width: 120px;
    font-size: 24px;
    border-radius: 0;
    cursor: pointer;
    >span {
      &:before {
        color: $white;
      }
    }
  }
  ul {
    position: relative;
    top: 0px;
    display: inline-block;
    margin: 0;
    margin-left: 0px;
    padding: 0;
    list-style-type: none;
    li {
      float: left;
      border-right: 1px solid rgba(255,255,255,.54);
      line-height: 0.8rem;
      font-family: "Calibre-R", Arial, Helvetica, sans-serif;
      &:last-child {
        border: none;
      }
      >a {
        padding: 0 15px;
        font-size: 1.6rem;
        line-height: 1.6rem;
        color: #fff;
        opacity: .54;
       // webkit-transition: all 0.2s;
        transition: all 0.2s;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
          opacity: 1;
         // webkit-transition: all 0.2s;
          transition: all 0.2s;
          text-decoration: none;
        }
      }
    }
  }
  div.version {
  	float: right;
  	margin-top: 15px;
  	color: rgba(255,255,255,.54);
  	font-style: italic;
    transition: all 200ms;
    &:hover {
      color: rgba(255,255,255,1);
      transition: all 200ms;
    }
  }
}
