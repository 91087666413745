html, body {
	width:$full;
	height:$full;
}

body {
	position:relative;
	background-repeat: no-repeat; 
	background-position: center -100px; 
	background-attachment:fixed;
	color: #767676;
}

a {
	cursor: pointer;
}

ul {
	list-style:none;
	padding:0px;
	margin:0px;	
}

.panel {
	margin-bottom:3px;
	@include box-shadow(none);
}

.panel-body {
	padding:0px;	
}

.input-group-addon {
	border:none;
}

.navbar {
	margin-bottom:0px;
	@include border-radius(0px);
	border:none;
}