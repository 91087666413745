// dimentions

$full: 100%;

// colors

$light: #ffffff;
$primaryColor: #006a4d;
$secondaryColor: #1d2e3e;
$secondaryGreen: #00a657;
$thirdGreen: #058565;
$flatRed: #ff5649;
$flatYellow: #ffc107;
$blue: #0354a6;

$greysecondary: #6d6d6d;
$grey600: #868686;
$grey500: #eeeeee;


/* base colors */
$white: #ffffff;
$black: #000000;

$primary700: #003F2D;
$primary600: #006a4d;
$primary500: #50AF78;

/*
lighten($black, 20%); 			#333333
lighten($black, 40%); 			#666666
lighten($black, 60%); 			#999999
lighten($black, 80%); 			#cccccc
lighten($black, 87%); 			#dedede
lighten($black, 93%); 			#ededed
lighten($black, 95%); 			#f2f2f2
*/

/* Branding colors */
$cbre-primary: #006a4d;
/* rgba(0,106,77,1) */
$cbre-lime: #69be28;
/* rgba(105,190,40,1) */
$cbre-enterprising: #004b35;
/* rgba(0,75,53,1) */
$cbre-refreshing: #003F2D;
/* rgba(0,166,87,1) */
$cbre-energizing: #bfd857;
/* rgba(191,216,87,1) */

$cbre-neutral: rgba(219, 213, 191, 1);
$cbre-neutral65: rgba(236, 233, 221, 1);
$cbre-neutral35: rgba(245, 243, 237, 1);

$cbre-tertiary-blue: #00b2dd;
/* rgba(0,178,221,1) */
$cbre-tertiary-magenta: #ec008c;
/* rgba(236,0,140,1) */
$cbre-tertiary-purple: #a23f97;
/* rgba(162,63,151,1) */
$cbre-tertiary-orange: #f58220;
/* rgba(245,130,32,1) */
$cbre-tertiary-yellow: #ffdd00;
/* rgba(255,221,0,1) */


/* Text colors */
$text-default: lighten($black, 20%);

/* Anchor colors */
$link-default: $cbre-primary;
$link-default-hover: $cbre-lime;

/* Button colors */
$button-primary: $primary700;
$button-cancel: $cbre-enterprising;
$button-save: $cbre-primary;
$button-disabled: #E3E3E3;

$button-text: $white;
$button-text-hover: $cbre-energizing;

/* Border colors */
$border-default: lighten($black, 80%);

/* Table colors */
$table-bg: $white;
$table-th-bg: #def5eb;
$table-th-top: $cbre-refreshing;
$table-row-alt-bg: #f2f2f2;
$table-row-border: #5acd9e;

/* Forms */
$form-bg: lighten($black, 94%);

/* Others */