@import "variables";

@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.eot?1nfxgn');
  src: url('/assets/fonts/icomoon.eot?1nfxgn#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?1nfxgn') format('truetype'),
    url('/assets/fonts/icomoon.woff?1nfxgn') format('woff'),
    url('/assets/fonts/icomoon.svg?1nfxgn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon", Arial, Helvetica, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\e986";
}

.icon-download:before {
  content: "\e9c7";
}

.icon-logo:before {
  content: "\e900";
}

.icon-plus:before {
  content: "\ea0a";
}

.icon-checkmark:before {
  content: "\ea10";
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
    local('MaterialIconsRound-Regular'),
    url('/assets/fonts/materialIcons/MaterialIconsRound-Regular.otf') format('otf')
}

.material-icons {
  font-family: "Material Icons", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  cursor: pointer;
  color: $primary700;
  font-size: x-large;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &.white {
    color: $white;
  }
  &.disabled {
    background: rgba(26, 26, 26, 0.12);
    border-radius: 4px;
    color: rgba(26, 26, 26, 0.46) !important;
}
}

.material-icons-delete {
  font-family: "Material Icons", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  cursor: pointer;
  color: #FF543E;
  font-size: x-large;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  &.white {
    color: $white;
  }
}