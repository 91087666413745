.navbar {
	min-height: auto;
    position: fixed;
    z-index: 1200;
    width: 296px;
    top: 72px;
    left: -300px;
    @include box-shadow(0px 10px 10px rgba(0,0,0,0.5));
	@include transition(all);
	@include transition-duration(.3s);
	
	&.active {
		left: 0px;
	}

	.navbar-nav {
		width:$full;

		li {
			
			width:$full;

			a {
				padding:0px 15px;
				line-height:45px;
				font-size:18px;
				
				i {
					margin-right:5px;	
				}

				span.fa.fa-chevron-left,
				span.fa.fa-chevron-down {
					float: right;
					margin-top: 20px;
					font-size: 12px;
				}
				
			}
			
			ul.dropdown-menu {
				position:relative;
				z-index:10;
				margin-top:-2px;
				width:$full;
				padding:0px;
				@include box-shadow(none);

				li {
					a {
						padding-left: 36px;
						font-size: 16px;
						line-height: 30px;
					}

				}

			}
			
		}
		
	}
}

/*  Added for new UI - starting 09/19/17 */
.sidenav {
	a.sidenav--link {
		span.fa-chevron-right {
			float: right;
			position: relative;
				top: 15px;
			margin: 0;
			font-size: 1.4rem;
		}
		span.fa-chevron-down {
			float: right;
			position: relative;
				top: -5px;
			font-size: 1.4rem !important;
		}
	}
	&__menu {
		border: none;
		li {
			a {
				color: $link-default;
				&:hover {
					color: $link-default-hover;
				}
			}
		}
	}
}



.open-dropdown > .sidenav__menu {
	display: block;
}