.btn {
	border-radius: 8px;
	text-transform: uppercase;
	transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	font-weight: 600;
	font-size: 16px;
	height: 40px;
	width: auto;
	line-height: 25px;
	text-align: center;
	border: 1px solid #003f2d;
	color: #003f2d;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
	outline: 0 !important;
}

.btn[disabled] {
	//background: #003f2d;
	font-family: "calibre-R", Arial, Helvetica, sans-serif;
	background:#CCCCCC; 
	color:#7A7A7A;
	border:1px solid #cccccc; 
	//color: $white;
}

.btn[disabled]:hover {
	 background:#CCCCCC;
	color: #7A7A7A;
}

.btn-primary{
    background:#003f2d;
	color:  $white;
}
.btn-default {
	border: 1px solid $button-primary;
	background:$white;
	color:#003f2d  ;
	transition: all 200ms ease-out;
}

// .btn-primary:hover,
// .btn-default:hover {
// 	background: #D3D8D7;
// 	//border-color: $button-primary;
// 	//color: $white;
// 	transition: all 200ms ease-out;
// 	border:1px solid #003F2D;
// 	 color:#003F2D; 
// }

.btn-default:active,
.btn-default:focus,
.btn-primary:active
{
    background: #003f2d;
	color: $white;
}
.btn-primary:focus {
	//background: #f3f3f4;
	border-color: $button-primary;
	//color: $button-primary;
	background: #003f2d;
	color: $white;
	transition: all 200ms ease-out;
}